import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { StorageKey, StorageService } from 'src/app/core/storage.service';
import { ItemMultiSelect } from 'src/app/shared/components/multiselect/multiselect.component';
import { UserType } from 'src/app/shared/container/header/header.component';
import { SharedModule } from 'src/app/shared/shared.module ';
import { SellerService } from '../../seller.service';

@Component({
  selector: 'seller-dialog-impersonation',
  templateUrl: './seller-dialog-impersonation.component.html',
  styleUrls: ['./seller-dialog-impersonation.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class SellerDialogImpersonationComponent implements OnInit {
  users$!: Observable<ItemMultiSelect[]>;
  selectedUser!: ItemMultiSelect;
  multiple = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly sellerService: SellerService,
    private readonly storageService: StorageService,
    private readonly dialogRef: DialogRef,
    private readonly msalService: MsalService
  ) {}

  ngOnInit(): void {
    this.users$ = this.sellerService.getUsersToImpersonate().pipe(
      map((users: any[]) => {
        return users.filter(
          (user: any) =>
            user.email !==
            this.msalService.instance.getActiveAccount()?.username
        );
      }),
      map((users: any[]) => {
        if (this.data.onlySupervisor) {
          return users.filter(
            (user: any) => user.user_type === UserType.SUPERVISOR
          );
        }
        return users;
      }),
      map((users: any[]) => {
        return users.map((user: any) => {
          return {
            id: user.email,
            name: user.email,
          } as ItemMultiSelect;
        });
      }),
      tap((users: ItemMultiSelect[]) => {
        const client_email = this.storageService.getData(
          StorageKey.ClientEmail
        );
        const selectedUser = users.find(
          (x) => x.name === client_email
        ) as ItemMultiSelect;
        if (selectedUser) {
          this.selectedUser = selectedUser;
        }
      })
    );
  }

  onChangeStates(selected: ItemMultiSelect[] | ItemMultiSelect) {
    this.selectedUser = selected as ItemMultiSelect;
  }

  onClearStates() {
    this.selectedUser = { id: '', name: '' };
  }

  impersonate() {
    this.sellerService.postImpersonate(this.selectedUser.id).subscribe(() => {
      this.closeDialog();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
