<div class="home-desktop-container">
  <mat-toolbar class="home-desktop">
    <div class="content">
      <div>
        <div class="logo">
          <div class="logo-info">
            <img class="suvinil" src="assets/logo.svg" alt="logo" />
            <div class="line"></div>
            <img class="glasu" src="assets/logo-glasu.svg" alt="logo-glasu" />
          </div>
        </div>
        <div class="version">
          <span class="name">Vendas</span>
          <span class="number">4.0</span>
        </div>
      </div>

      <div class="subject-content">
        <div class="subject-column separate">
          <div class="subject">
            <div class="welcome">
              <div class="name">
                Bem vindo, <b>{{ profile.name }}</b>
              </div>
            </div>
            <div class="company">Distribuidora Premium</div>
          </div>
          <div class="impersonation-column" [class.hidden]="!showButton">
            <div class="button-container">
              <button mat-raised-button (click)="openModalImpersionationDesktop()"
                [class.impersonated]="profile.is_impersonating">
                <mat-icon class="material-icons icon-impersionation" [svgIcon]="
                    profile.is_impersonating
                      ? 'menu-ic-impersonation-red'
                      : 'menu-ic-impersonation'
                  " [inline]="true"></mat-icon>
                <span class="text-button">
                  @if (profile.is_impersonating) { Sair do Supervisor } @else { Acessar
                  Supervisor }
                </span>
              </button>
            </div>
          </div>

          @if (!showNetworkStatus) {
          <div class="subject-column">
            <div class="button">
              <button mat-raised-button>
                <mat-icon class="material-icons icon" [svgIcon]="'ic-offline'" [inline]="true"></mat-icon>
                <span class="text-button">OFFLINE</span>
              </button>
            </div>
          </div>
          }
        </div>
      </div>
      <div class="action-buttons">
        @if (showNetworkStatus) {
        <a class="subject-column" (click)="usabilityManual()">
          <mat-icon [inline]="true" class="material-icons help" svgIcon="ic-help">
          </mat-icon>
        </a>
        }
        <span class="logout" (click)="logout()">Sair</span>
      </div>
    </div>
  </mat-toolbar>
  <div class="buttons">
    <div [ngClass]="selectedButton === 'dashboard' ? 'selected' : 'unselected'">
      <button mat-raised-button (click)="dashboard()">
        <mat-icon class="material-icons icon" [svgIcon]="
            selectedButton === 'dashboard' ? 'dsh-ic-icon-white' : 'dsh-ic-icon'
          " [inline]="true"></mat-icon>
        <span class="text-button">Painel de Controle</span>
      </button>
    </div>
    <div [ngClass]="selectedButton === 'report' ? 'selected' : 'unselected'">
      <button mat-raised-button (click)="report()">
        <mat-icon class="material-icons icon" [svgIcon]="
            selectedButton === 'report'
              ? 'dsh-ic-report-white'
              : 'dsh-ic-report'
          " [inline]="true"></mat-icon>
        <span class="text-button">Reports</span>
      </button>
    </div>
  </div>
</div>