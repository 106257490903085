import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserData, UserType } from '../../container/header/header.component';
import { SharedModule } from '../../shared.module ';

export enum MenuOptions {
  LISTA_CLIENTES = 'Lista de Clientes',
  PAINEL_CONTROLE = 'Painel de Controle',
  ACESSAR_USUARIO = 'Acessar Usuário',
  AJUDA = 'Ajuda',
  SAIR = 'Sair',
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class MenuComponent {
  menuOptions = MenuOptions;
  profile: UserData;
  userType = UserType;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserData,
    private readonly dialogRef: MatDialogRef<MenuComponent>
  ) {
    this.profile = this.data;
  }

  closeMenu(item: string): void {
    this.dialogRef.close(item);
  }
}
