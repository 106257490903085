import { Component, inject, OnInit } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { ApiBridgeService } from './core/api-bridge.service';
import {
  ApplicationInsightsService,
  EventName,
} from './core/application-insights.service';
import { AuthService } from './core/auth.service';
import { DeviceService } from './core/device.service';
import { DialogService } from './core/dialog.service';
import { StorageKey, StorageService } from './core/storage.service';
import {
  DialogWarningSellerComponent,
  DialogWarningSellerData,
} from './shared/components/dsh-dialog-warning-seller/dsh-dialog-warning-seller.component';
import { UserData } from './shared/container/header/header.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly deviceService = inject(DeviceService);
  private readonly apiBridgeService = inject(ApiBridgeService);
  private readonly storageService = inject(StorageService);
  private readonly applicationInsightsService = inject(
    ApplicationInsightsService
  );
  private readonly dialogService = inject(DialogService);

  loginDisplay$: Observable<boolean> = this.authService.loginDisplayObs;
  isMobile: boolean;
  userData$!: Observable<UserData>;

  constructor() {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.userData$ = this.apiBridgeService.get('user_data').pipe(
      map((data: any) => {
        return data as UserData;
      }),
      tap(() => {
        this.applicationInsightsService.logEvent(EventName.HomeSuccess);
      }),
      catchError((error) => {
        if (!this.storageService.getData(StorageKey.ErrorUserData)) {
          this.storageService.saveData(StorageKey.ErrorUserData, true);
          this.applicationInsightsService.logEvent(EventName.UserDataError);
          window.location.href = window.location.pathname;
        } else {
          this.applicationInsightsService.logEvent(EventName.AccessError);
          this.accessErrorDialog();
        }
        return throwError(() => error);
      })
    );
  }

  private accessErrorDialog() {
    this.dialogService
      .openDialog(DialogWarningSellerComponent, {
        data: {
          buttonText: 'VOLTAR',
          message:
            'Houve um problema ao acessar sua conta. Para garantir sua segurança, precisamos fazer login novamente. Pedimos desculpas pelo transtorno.',
        } as DialogWarningSellerData,
      })
      .afterClosed()
      .pipe(
        tap(() => {
          this.storageService.clearData();
          this.authService.logout();
        })
      )
      .subscribe();
  }
}
