import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component } from '@angular/core';
import { PWAService } from 'src/app/core/pwa.service';
import { SharedModule } from '../../shared.module ';

@Component({
  selector: 'app-suggestion-toolbar',
  templateUrl: './suggestion-toolbar.component.html',
  styleUrls: ['./suggestion-toolbar.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  imports: [SharedModule],
})
export class SuggestionToolbarComponent {
  constructor(private readonly pwaService: PWAService) {}

  install() {
    this.pwaService.installPWA();
  }

  close() {
    this.pwaService.cancelSuggestion();
  }
}
