<mat-toolbar class="header">
  <div class="manifest">
    <div class="close">
      <mat-icon (click)="close()" [inline]="true" class="material-icons close" svgIcon="ic-close">
      </mat-icon>
    </div>
    <div class="content">
      <mat-icon [inline]="true" class="material-icons" svgIcon="ic-logo">
      </mat-icon>
    </div>
    <div class="android">
      <span>para Android</span>
    </div>
    <div class="button">
      <button mat-raised-button (click)="install()">
        <span class="text-button">INSTALAR</span>
      </button>
    </div>
  </div>
</mat-toolbar>