import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  ApplicationInsightsService,
  EventName,
} from '../core/application-insights.service';
import { AuthService } from '../core/auth.service';
import { StorageKey, StorageService } from '../core/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: false,
})
export class LoginComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly storageService: StorageService
  ) {
    document.body.style.background =
      'url(../../assets/login_background.png) no-repeat center center';
    document.body.style.backgroundSize = 'cover';
    document.body.style.height = '100vh';
    document.body.style.width = '100vw';
  }

  ngOnInit(): void {
    this.authService.loginDisplayObs.subscribe((loginDisplay) => {
      if (loginDisplay) {
        if (!this.storageService.getData(StorageKey.IsImpersonating)) {
          this.applicationInsightsService.logEvent(EventName.LoginSuccess);
        }
        this.router.navigate(['/client/list']);
      }
    });
  }

  loginRedirect(): void {
    this.authService.login();
  }

  loginManual(): void {
    const link = document.createElement('a');
    link.href = 'assets/manuals/acccess.pdf';
    link.download = 'manual_de_acesso.pdf';
    link.click();
  }
}
