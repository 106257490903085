export const environment = {
  production: false,
  serviceWorker: false,
  publicClient: false,
  metrics: false,
  instrumentationKey: 'ca5d00b0-2bf0-47f8-927d-7bfa51d536c9',
  amplitud: {
    eneable: true,
    amplitudeKey: '6290f74d547b6b804c446eaf7ecd35a4',
  },
  manuals: {
    access: 'https://sway.cloud.microsoft/s/PD4WC4OPH4SquLUj/embed',
    usability: 'https://sway.cloud.microsoft/s/uwcSEazSAbqduGNt/embed',
  },
  msalConfig: {
    auth: {
      clientId: '18d0d553-4a24-496a-a51d-a57660dcaff4',
      authority:
        'https://login.microsoftonline.com/ffe578c1-cc8e-4b24-97ca-109fbfccfefa',
    },
    redirectStartPage: '/client/list',
    sessionStorage: true,
  },
  apiConfig: {
    scopes: ['api://18d0d553-4a24-496a-a51d-a57660dcaff4/user_impersonation'],
    uri: 'https://dev.vendas-ia.basf.com/api/',
  },
};
