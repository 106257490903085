import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { EMPTY } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AmplitudeService } from './core/amplitude.service';
import { ApiBridgeService } from './core/api-bridge.service';
import { ApplicationInsightsService } from './core/application-insights.service';
import { AuthService } from './core/auth.service';
import { ErrorHandlerService } from './core/error-handler.service';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './core/init-msal.services';
import { LoaderInterceptor } from './core/loader.interceptor';
import { PWAService } from './core/pwa.service';
import { FailedComponent } from './failed/failed.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module ';

registerLocaleData(localePt);

export function initializeApp(
  pwaService: PWAService,
  authService: AuthService,
  applicationInsightsService: ApplicationInsightsService,
  amplitudeService: AmplitudeService
): () => void {
  return () => {
    pwaService.initPWA();
    authService.init();
    applicationInsightsService.init();
    amplitudeService.initAmplitude();
    return EMPTY;
  };
}

@NgModule({
  declarations: [AppComponent, FailedComponent, LoginComponent],
  imports: [
    HeaderComponent,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    SharedModule,
    HttpClientModule,
    MsalModule,
    ScrollingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [
        PWAService,
        AuthService,
        ApplicationInsightsService,
        AmplitudeService,
      ],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ApiBridgeService,
    PWAService,
    provideCharts(withDefaultRegisterables()),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
