import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/core/dialog.service';
import { SellerDialogImpersonationComponent } from 'src/app/modules/seller/seller-impersonation/dialog/seller-dialog-impersonation.component';
import {
  MenuComponent,
  MenuOptions,
} from 'src/app/shared/components/menu/menu.component';
import { SharedModule } from '../../../shared.module ';
import { UserData, UserType } from '../header.component';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  imports: [SharedModule],
})
export class HeaderMobileComponent {
  @Input() showNetworkStatus: boolean = true;
  @Input() profile!: UserData;

  @Output() clickLogout = new EventEmitter();

  constructor(
    private readonly dialogService: DialogService,
    private readonly router: Router
  ) {}

  openMenu(): void {
    this.dialogService
      .openDialog(MenuComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: '100%',
        height: '100%',
        panelClass: 'full-screen-dialog',
        data: this.profile,
      })
      .afterClosed()
      .subscribe((menuItem: string | undefined) => {
        switch (menuItem) {
          case MenuOptions.LISTA_CLIENTES:
            this.router.navigate(['/client/list/']);
            break;
          case MenuOptions.PAINEL_CONTROLE:
            this.router.navigate(['/seller/list']);
            break;
          case MenuOptions.ACESSAR_USUARIO:
            this.openModaImpersonation();
            break;
          case MenuOptions.AJUDA:
            this.usabilityManual();
            break;
          case MenuOptions.SAIR:
            this.clickLogout.emit();
            break;
        }
      });
  }

  openModaImpersonation(): void {
    this.dialogService
      .openDialog(SellerDialogImpersonationComponent, {
        width: '80%',
        height: '280px',
        position: { top: '10vh' },
        data: {
          onlySupervisor: false,
        },
      })
      .afterClosed()
      .subscribe(() => {
        window.location.reload();
      });
  }

  usabilityManual() {
    const link = document.createElement('a');
    link.href =
      this.profile.user_type !== UserType.SELLER
        ? 'assets/manuals/usability-supervisor.pdf'
        : 'assets/manuals/usability.pdf';

    link.download = 'manual_de_usabilidade.pdf';
    link.click();
  }
}
