import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedModule } from '../../shared.module ';

export interface DialogWarningSellerData {
  message: string;
  buttonText: string;
}

@Component({
  selector: 'dsh-dialog-warning-seller',
  templateUrl: './dsh-dialog-warning-seller.component.html',
  styleUrls: ['./dsh-dialog-warning-seller.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class DialogWarningSellerComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWarningSellerData,
    private readonly dialogRef: DialogRef
  ) {}

  close() {
    this.dialogRef.close();
  }
}
