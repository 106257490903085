<mat-toolbar class="other-header">
  <div class="content">
    <div class="logo">
      <img class="image" src="assets/logo.svg" alt="logo" />
      <div class="line"></div>
      <img class="image glasu" src="assets/logo-glasu.svg" alt="logo-glasu" />
    </div>
    <div class="version">
      <span class="name">Vendas</span>
      <span class="number">4.0</span>
    </div>
  </div>
  <div class="back-content">
    <mat-icon (click)="backButton()" [inline]="true" class="material-icons back" svgIcon="ic-arrow-left-circle">
    </mat-icon>
  </div>
</mat-toolbar>