@if (showNetworkStatus$ | async; as showNetworkStatus) {

<div class="header-container">
  @if (showsuggesion$ | async) {
  <app-suggestion-toolbar></app-suggestion-toolbar>
  }
  @switch (getHeaderType()) {
  @case (HeaderTypeEnum.HOME) {
  <app-header-mobile [showNetworkStatus]="showNetworkStatus" [profile]="profile"
    (clickLogout)="logout()"></app-header-mobile>
  }
  @case (HeaderTypeEnum.FILTER) {
  <app-header-filter></app-header-filter>
  }
  @case (HeaderTypeEnum.OTHER) {
  <app-header-other></app-header-other>
  }
  @case (HeaderTypeEnum.HOME_DESKTOP) {
  <app-header-desktop [profile]="profile" [showNetworkStatus]="showNetworkStatus"
    (clickLogout)="logout()"></app-header-desktop>
  }
  }
</div>
}