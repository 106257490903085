<div class="container">

  @if (users$ | async; as users) {
  <div class="content">
    <div class="close-container">
      <mat-icon (click)="closeDialog()" [inline]="true" class="material-icons icon-close" svgIcon="menu-ic-close">
      </mat-icon>
    </div>
    <div class="title-container">
      <span>
        @if (this.data.onlySupervisor) {
        Acessar supervisor:
        } @else {
        Acessar usuário:
        }
      </span>
    </div>

    <div class="multiselect-container">
      <div>

        <app-multiselect [items]="users" [selectedItems]="selectedUser" [closeOnSelect]="true"
          [placeholder]="this.data.onlySupervisor ? 'Supervisores a impersona' : 'Usuarios a impersonar'"
          [multiple]="false" (changeOneItem)="onChangeStates($event)" (clear)="onClearStates()"></app-multiselect>


      </div>
    </div>
    <div class="button-container">
      <button (click)="impersonate()" mat-raised-button>
        <span class="text-button">ACESSAR</span>
      </button>
    </div>
  </div>
  }
</div>