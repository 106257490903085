@if (isMobile) {
<app-spinner></app-spinner>
}

@if ((loginDisplay$ | async)) {
@if (userData$ | async; as userData){
<app-header [profile]="userData"></app-header>
}
}

<router-outlet></router-outlet>