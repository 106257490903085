import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',

})
export class DialogService {
  constructor(private readonly dialogService: MatDialog, private readonly matSnackBarService: MatSnackBar) { }

  openDialog(component: any, config?: MatDialogConfig<any>) {
    return this.dialogService.open(component, config);
  }

  openSnackBar(component: any, config?: MatSnackBarConfig<any>) {
    return this.matSnackBarService.openFromComponent(component, config);
  }


}
