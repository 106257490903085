import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { DialogService } from 'src/app/core/dialog.service';
import { SellerDialogImpersonationComponent } from 'src/app/modules/seller/seller-impersonation/dialog/seller-dialog-impersonation.component';
import { SellerService } from 'src/app/modules/seller/seller.service';
import {
  DialogWarningSellerComponent,
  DialogWarningSellerData,
} from 'src/app/shared/components/dsh-dialog-warning-seller/dsh-dialog-warning-seller.component';
import { SharedModule } from '../../../shared.module ';
import { UserData, UserType } from '../header.component';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  imports: [SharedModule],
})
export class HeaderDesktopComponent implements OnChanges {
  @Input() profile!: UserData;
  @Input() showNetworkStatus: boolean = false;

  @Output() clickLogout = new EventEmitter();

  UserTypeEnum = UserType;
  selectedButton: string = 'dashboard';
  showButton: boolean = false;

  constructor(
    private readonly dialogService: DialogService,
    private readonly sellerService: SellerService,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.location.path().indexOf('/seller/dashboard/report') > -1) {
      this.selectedButton = 'report';
    }
    if (this.profile.user_type === UserType.SELLER) {
      const warningData = this.profile.is_impersonating
        ? this.getImpersonationWarning()
        : this.getMobileAccessWarning();
      this.dialogService
        .openDialog(DialogWarningSellerComponent, {
          data: warningData,
        })
        .afterClosed()
        .pipe(
          tap(() => {
            if (this.profile.is_impersonating) {
              this.turnOffImpersonation();
            } else {
              this.clickLogout.emit();
            }
          })
        )
        .subscribe();
    }
    this.showButton =
      this.profile.is_impersonating ||
      this.profile.user_type === UserType.EXECUTIVE;
  }

  openModalImpersionationDesktop() {
    if (this.profile.is_impersonating) {
      this.turnOffImpersonation();
    } else {
      this.openModaImpersonation();
    }
  }

  openModaImpersonation(): void {
    this.dialogService
      .openDialog(SellerDialogImpersonationComponent, {
        width: '580px',
        height: '280px',
        data: {
          onlySupervisor: true,
        },
      })
      .afterClosed()
      .subscribe(() => {
        window.location.reload();
      });
  }

  turnOffImpersonation(): void {
    this.sellerService
      .postImpersonate('')
      .pipe(
        tap(() => {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        })
      )
      .subscribe();
  }

  dashboard(): void {
    this.router.navigate(['/seller/dashboard/home']);
    this.selectedButton = 'dashboard';
  }

  report(): void {
    this.router.navigate(['/seller/dashboard/report']);
    this.selectedButton = 'report';
  }

  usabilityManual() {
    const link = document.createElement('a');
    link.href = 'assets/manuals/dashboard-desktop.pdf';
    link.download = 'manual_de_usabilidade.pdf';
    link.click();
  }

  logout(): void {
    this.clickLogout.emit();
  }

  private getImpersonationWarning(): DialogWarningSellerData {
    return {
      buttonText: 'DESPERSONALIZAR',
      message:
        'Você está acessando a conta de um vendedor, nós o despersonalizaremos para continuar',
    };
  }

  private getMobileAccessWarning(): DialogWarningSellerData {
    return {
      buttonText: 'VOLTAR',
      message: 'Esta conta só pode ser acessada na versão mobile.',
    };
  }
}
