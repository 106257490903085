<mat-toolbar class="home-header">
  <div class="content">
    <div class="logo-container">
      <div class="logo">
        <div class="logo-info">
          <img class="suvinil" src="assets/logo.svg" alt="logo" />
          <div class="line"></div>
          <img class="glasu" src="assets/logo-glasu.svg" alt="logo-glasu" />
        </div>
        <div class="version">
          <span class="name">Vendas</span>
          <span class="number">4.0</span>
        </div>
      </div>

      @if (showNetworkStatus) {
      <div class="menu-container">
        <button mat-raised-button (click)="openMenu()">
          <span class="text-button">MENU </span>
        </button>
      </div>
      }
    </div>

    <div class="subject-content">
      <div class="subject-column separate">
        <div class="subject">
          <div class="welcome">
            <div class="name">Olá, {{ profile.name }}</div>
          </div>
          <div class="company">
            Distribuidora <br />
            Premium
          </div>
        </div>
        @if (!showNetworkStatus) {
        <div class="subject-column">
          <div class="button">
            <button mat-raised-button>
              <mat-icon class="material-icons icon" [svgIcon]="'ic-offline'" [inline]="true"></mat-icon>
              <span class="text-button">OFFLINE</span>
            </button>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</mat-toolbar>