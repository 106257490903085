import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { SharedModule } from "../../../../../app/shared/shared.module ";



@Component({
  selector: 'seller-snack-bar-impersonation',
  templateUrl: './seller-snack-bar-impersonation.component.html',
  styleUrls: ['./seller-snack-bar-impersonation.component.scss'],
  standalone: true,
  imports: [SharedModule]
})
export class SellerSnackBarImpersonationComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    private readonly snackBarRef: MatSnackBarRef<SellerSnackBarImpersonationComponent>
  ) { }

  dismiss() {
    this.snackBarRef.dismiss();
  }

  off() {
    this.snackBarRef.dismissWithAction();
  }


}


