@if (data.position === 'center') {
<div class="full-width-snack">
  <div class="title-section">
    <div class="label">
      Visualizando como:
    </div>
    <div class="name">
      {{data.name}}
    </div>
  </div>
  <div class="buttons-section">
    <button mat-raised-button (click)="dismiss()">
      <span class="text-button">OCULTAR</span>
    </button>
    <button mat-raised-button class="cancel" (click)="off()">
      <span class="text-button">SAIR</span>
    </button>
  </div>
</div>

} @else {
<div class="partial-width-snack" (click)="dismiss()">
  <mat-icon [inline]="true" class="material-icons eye" [svgIcon]="'ic-eye'">
  </mat-icon>
</div>
}