<div class="menu-overlay" (click)="closeMenu(menuOptions.LISTA_CLIENTES)"></div>
<div class="menu-container">
  <div class="menu-header">
    <div class="logo-container">
      <div class="logo">
        <div class="logo-info">
          <img class="suvinil" src="assets/logo.svg" alt="logo" />
          <div class="line"></div>
          <img class="glasu" src="assets/logo-glasu.svg" alt="logo-glasu" />
        </div>
        <div class="version">
          <span class="name">Vendas</span>
          <span class="number">4.0</span>
        </div>
      </div>
    </div>
    <mat-icon (click)="closeMenu(menuOptions.LISTA_CLIENTES)" [inline]="true" class="material-icons menu-close"
      svgIcon="menu-ic-close">
    </mat-icon>
  </div>
  <div class="menu-title">
    <span>Menu</span>
  </div>

  <nav class="menu-nav">
    <ul>
      <li (click)="closeMenu(menuOptions.LISTA_CLIENTES)">
        <mat-icon [inline]="true" class="material-icons menu-icon" svgIcon="menu-ic-list-clients">
        </mat-icon>
        <a>Lista de Clientes</a>
      </li>
      @if (profile.can_impersonate && profile.user_type !== userType.SELLER) {
      <li (click)="closeMenu(menuOptions.PAINEL_CONTROLE)">
        <mat-icon [inline]="true" class="material-icons menu-icon" svgIcon="menu-ic-dashboard">
        </mat-icon><a>Painel de Controle</a>
      </li>
      <li (click)="closeMenu(menuOptions.ACESSAR_USUARIO)"> <mat-icon [inline]="true" class="material-icons menu-icon"
          svgIcon="menu-ic-impersonation">
        </mat-icon><a>Acessar Usuário</a></li>
      }
      <li (click)="closeMenu(menuOptions.AJUDA)"> <mat-icon [inline]="true" class="material-icons menu-icon"
          svgIcon="menu-ic-help">
        </mat-icon><a>Ajuda</a></li>
      <li (click)="closeMenu(menuOptions.SAIR)" class="menu-exit"> <mat-icon [inline]="true"
          class="material-icons menu-icon" svgIcon="menu-ic-logout">
        </mat-icon><a>Sair</a></li>
    </ul>
  </nav>
</div>