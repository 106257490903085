import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '../../../shared.module ';

@Component({
  selector: 'app-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  imports: [SharedModule],
})
export class HeaderFilterComponent {
  constructor(
    private readonly location: Location,
    private readonly router: Router
  ) {}

  backButton(): void {
    if (this.location.path().indexOf('/client/comment') > -1) {
      this.router.navigate([
        `/product/list/${this.location.path().substring(16)}`,
      ]);
    } else if (
      this.location.path().indexOf('/product/list/') > -1 ||
      this.location.path().indexOf('/client/filter/') > -1
    ) {
      this.router.navigate(['/client/list/']);
    } else {
      this.location.back();
    }
  }
}
