<div class="container">


  <div class="content">
    <div class="warning-container">
      <mat-icon [inline]="true" class="material-icons icon-warning" svgIcon="dsh-ic-warning-seller">
      </mat-icon>
    </div>
    <div class="title-container">
      <span>
        ATENÇÃO!
      </span>
    </div>
    <div class="subtitle-container">
      <span>
        {{ data.message }}
      </span>
    </div>
    <div class="button-container">
      <button (click)="close()" mat-raised-button>
        <span class="text-button">
          {{ data.buttonText }}
        </span>
      </button>
    </div>
  </div>

</div>