import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import {
  ApplicationInsightsService,
  EventName,
} from 'src/app/core/application-insights.service';
import { AuthService } from 'src/app/core/auth.service';
import { DialogService } from 'src/app/core/dialog.service';
import { StorageKey, StorageService } from 'src/app/core/storage.service';
import { SellerSnackBarImpersonationComponent } from 'src/app/modules/seller/seller-impersonation/snack-bar-impersonation/seller-snack-bar-impersonation.component';
import { SellerService } from 'src/app/modules/seller/seller.service';
import { NetworkService } from '../../../core/network.service';
import { PWAService } from '../../../core/pwa.service';
import { SuggestionToolbarComponent } from '../../components/suggestion-toolbar/suggestion-toolbar.component';
import { SharedModule } from '../../shared.module ';
import { HeaderDesktopComponent } from '../header/desktop/header-desktop.component';
import { HeaderFilterComponent } from '../header/filter/header-filter.component';
import { HeaderMobileComponent } from '../header/mobile/header-mobile.component';
import { HeaderOtherComponent } from '../header/other/header-other.component';

export enum HeaderType {
  HOME = 'home',
  FILTER = 'filter',
  OTHER = 'other',
  HOME_DESKTOP = 'home-desktop',
}

export enum UserType {
  SUPERVISOR = 'supervisor',
  SELLER = 'seller',
  EXECUTIVE = 'executive',
}

export interface UserData {
  id: number;
  email: string;
  name: string;
  surname: string;
  user_type: UserType;
  can_impersonate: boolean;
  is_impersonating: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  imports: [
    SharedModule,
    SuggestionToolbarComponent,
    HeaderOtherComponent,
    HeaderFilterComponent,
    HeaderDesktopComponent,
    HeaderMobileComponent,
  ],
})
export class HeaderComponent implements OnChanges {
  @Input() profile!: UserData;

  headerType: HeaderType = HeaderType.HOME;
  HeaderTypeEnum = HeaderType;

  showsuggesion$!: Observable<boolean>;
  showNetworkStatus$!: Observable<boolean>;

  userType: UserType = UserType.SELLER;

  UserTypeEnum = UserType;

  isImpersonated: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly pwa: PWAService,
    private readonly location: Location,
    private readonly networkService: NetworkService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly storageService: StorageService,
    private readonly dialogService: DialogService,
    private readonly sellerService: SellerService
  ) {
    this.showsuggesion$ = this.pwa.pwaSuggestion;
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }

  ngOnChanges(): void {
    if (this.profile) {
      this.profile.name = this.profile.name.split(' ')[0];
      this.storageService.saveData(StorageKey.ClientEmail, this.profile.email);
      this.userType = this.profile.user_type;
      this.setImpersionation(this.profile.is_impersonating);
    }
  }

  setImpersionation(is_impersonating: boolean): void {
    this.isImpersonated = is_impersonating;
    this.storageService.saveData(
      StorageKey.IsImpersonating,
      this.isImpersonated
    );
    if (this.isImpersonated) {
      this.openSnackBar();
    }
  }

  getHeaderType(): HeaderType {
    document.body.style.background = 'white';
    if (this.location.path().indexOf('/client/list') > -1) {
      this.headerType = HeaderType.HOME;
    } else if (this.location.path().indexOf('/filter') > -1) {
      this.headerType = HeaderType.FILTER;
    } else if (this.location.path().indexOf('/seller/dashboard') > -1) {
      this.headerType = HeaderType.HOME_DESKTOP;
    } else {
      this.headerType = HeaderType.OTHER;
    }
    return this.headerType;
  }

  logout(): void {
    this.storageService.clearData();
    this.storageService.clearServiceWorkerCache();
    this.applicationInsightsService.logEvent(EventName.Logout);
    this.authService.logout();
  }

  openSnackBar(position: 'left' | 'center' | 'right' = 'center') {
    const snackBarRef = this.dialogService.openSnackBar(
      SellerSnackBarImpersonationComponent,
      {
        horizontalPosition: position,
        verticalPosition: 'bottom',
        panelClass: [
          position === 'center'
            ? 'snackbar-full-width'
            : 'snackbar-right-width',
        ],
        data: {
          name: this.profile.name,
          position,
        },
      }
    );

    snackBarRef.afterDismissed().subscribe((info) => {
      if (info.dismissedByAction) {
        this.turnOffImpersonation();
      } else {
        const nextPosition = position === 'center' ? 'right' : 'center';
        this.openSnackBar(nextPosition);
      }
    });
  }

  turnOffImpersonation(): void {
    this.sellerService
      .postImpersonate('')
      .pipe(
        tap(() => {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        })
      )
      .subscribe();
  }
}
